




































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: 'ConfirmModal'
})
export default class ConfirmModal extends Vue {
  @Prop({type: String, required: true}) label!: string;
  @Prop({type: String, default: 'Remove selected'}) title!: string;
  @Prop({type: Boolean, required: true}) showPopup!: boolean;
  @Prop({type: String, default: 'Delete'}) deleteBtnText!: string;
  @Prop({type: String, default: '#FE4D97'}) deleteBtnColor!: string;
  @Prop({type: Boolean, default: false}) loading!: boolean;

  @Watch('showPopup')
  observeShowPopup(): void {
    this.handleScreenResize();
  }

  @Emit('close')
  closePopup(): void {
  }

  @Emit('confirm')
  confirm(): void {
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  handleScreenResize(): void {
    if (!this.isMobile || !this.showPopup) {
      return;
    }

    const dialogContainer = this.$refs.dialogContainer as HTMLElement;
    dialogContainer.style.height = `${window.innerHeight - 58}px`;
  }
}
